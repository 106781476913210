(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("ol/geom/Point.js"), require("ol/easing.js"), require("ol/layer/Tile.js"), require("ol/layer/Image.js"), require("ol/proj.js"), require("ol/source/ImageStatic.js"), require("ol/source/ImageWMS.js"), require("ol/source/TileImage.js"), require("ol/source/TileWMS.js"), require("ol/source/VectorTile.js"), require("ol/source/Image.js"), require("ol/source.js"), require("ol/format/MVT.js"), require("ol/style/Style.js"), require("ol/style/Stroke.js"), require("ol/render.js"), require("ol/util.js"), require("ol/structs/LRUCache.js"), require("ol/tilegrid.js"), require("ol/tileurlfunction.js"), require("ol/render/Feature.js"), require("ol/layer/VectorTile.js"), require("ol/extent.js"), require("ol/Observable.js"), require("ol/layer/Group.js"), require("ol/layer/BaseVector.js"), require("ol/source/Vector.js"), require("ol/layer/Layer.js"), require("ol/source/Cluster.js"), require("ol/layer/Vector.js"), require("ol/style/Icon.js"), require("ol/geom/Polygon.js"), require("ol/geom/SimpleGeometry.js"), require("ol/geom.js"), require("ol/Overlay.js"));
	else if(typeof define === 'function' && define.amd)
		define(["ol/geom/Point.js", "ol/easing.js", "ol/layer/Tile.js", "ol/layer/Image.js", "ol/proj.js", "ol/source/ImageStatic.js", "ol/source/ImageWMS.js", "ol/source/TileImage.js", "ol/source/TileWMS.js", "ol/source/VectorTile.js", "ol/source/Image.js", "ol/source.js", "ol/format/MVT.js", "ol/style/Style.js", "ol/style/Stroke.js", "ol/render.js", "ol/util.js", "ol/structs/LRUCache.js", "ol/tilegrid.js", "ol/tileurlfunction.js", "ol/render/Feature.js", "ol/layer/VectorTile.js", "ol/extent.js", "ol/Observable.js", "ol/layer/Group.js", "ol/layer/BaseVector.js", "ol/source/Vector.js", "ol/layer/Layer.js", "ol/source/Cluster.js", "ol/layer/Vector.js", "ol/style/Icon.js", "ol/geom/Polygon.js", "ol/geom/SimpleGeometry.js", "ol/geom.js", "ol/Overlay.js"], factory);
	else if(typeof exports === 'object')
		exports["olcs"] = factory(require("ol/geom/Point.js"), require("ol/easing.js"), require("ol/layer/Tile.js"), require("ol/layer/Image.js"), require("ol/proj.js"), require("ol/source/ImageStatic.js"), require("ol/source/ImageWMS.js"), require("ol/source/TileImage.js"), require("ol/source/TileWMS.js"), require("ol/source/VectorTile.js"), require("ol/source/Image.js"), require("ol/source.js"), require("ol/format/MVT.js"), require("ol/style/Style.js"), require("ol/style/Stroke.js"), require("ol/render.js"), require("ol/util.js"), require("ol/structs/LRUCache.js"), require("ol/tilegrid.js"), require("ol/tileurlfunction.js"), require("ol/render/Feature.js"), require("ol/layer/VectorTile.js"), require("ol/extent.js"), require("ol/Observable.js"), require("ol/layer/Group.js"), require("ol/layer/BaseVector.js"), require("ol/source/Vector.js"), require("ol/layer/Layer.js"), require("ol/source/Cluster.js"), require("ol/layer/Vector.js"), require("ol/style/Icon.js"), require("ol/geom/Polygon.js"), require("ol/geom/SimpleGeometry.js"), require("ol/geom.js"), require("ol/Overlay.js"));
	else
		root["olcs"] = factory(root["ol/geom/Point.js"], root["ol/easing.js"], root["ol/layer/Tile.js"], root["ol/layer/Image.js"], root["ol/proj.js"], root["ol/source/ImageStatic.js"], root["ol/source/ImageWMS.js"], root["ol/source/TileImage.js"], root["ol/source/TileWMS.js"], root["ol/source/VectorTile.js"], root["ol/source/Image.js"], root["ol/source.js"], root["ol/format/MVT.js"], root["ol/style/Style.js"], root["ol/style/Stroke.js"], root["ol/render.js"], root["ol/util.js"], root["ol/structs/LRUCache.js"], root["ol/tilegrid.js"], root["ol/tileurlfunction.js"], root["ol/render/Feature.js"], root["ol/layer/VectorTile.js"], root["ol/extent.js"], root["ol/Observable.js"], root["ol/layer/Group.js"], root["ol/layer/BaseVector.js"], root["ol/source/Vector.js"], root["ol/layer/Layer.js"], root["ol/source/Cluster.js"], root["ol/layer/Vector.js"], root["ol/style/Icon.js"], root["ol/geom/Polygon.js"], root["ol/geom/SimpleGeometry.js"], root["ol/geom.js"], root["ol/Overlay.js"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__206__, __WEBPACK_EXTERNAL_MODULE__177__, __WEBPACK_EXTERNAL_MODULE__164__, __WEBPACK_EXTERNAL_MODULE__330__, __WEBPACK_EXTERNAL_MODULE__24__, __WEBPACK_EXTERNAL_MODULE__606__, __WEBPACK_EXTERNAL_MODULE__469__, __WEBPACK_EXTERNAL_MODULE__623__, __WEBPACK_EXTERNAL_MODULE__886__, __WEBPACK_EXTERNAL_MODULE__622__, __WEBPACK_EXTERNAL_MODULE__673__, __WEBPACK_EXTERNAL_MODULE__432__, __WEBPACK_EXTERNAL_MODULE__401__, __WEBPACK_EXTERNAL_MODULE__193__, __WEBPACK_EXTERNAL_MODULE__51__, __WEBPACK_EXTERNAL_MODULE__817__, __WEBPACK_EXTERNAL_MODULE__280__, __WEBPACK_EXTERNAL_MODULE__750__, __WEBPACK_EXTERNAL_MODULE__628__, __WEBPACK_EXTERNAL_MODULE__423__, __WEBPACK_EXTERNAL_MODULE__115__, __WEBPACK_EXTERNAL_MODULE__996__, __WEBPACK_EXTERNAL_MODULE__235__, __WEBPACK_EXTERNAL_MODULE__911__, __WEBPACK_EXTERNAL_MODULE__604__, __WEBPACK_EXTERNAL_MODULE__515__, __WEBPACK_EXTERNAL_MODULE__830__, __WEBPACK_EXTERNAL_MODULE__582__, __WEBPACK_EXTERNAL_MODULE__63__, __WEBPACK_EXTERNAL_MODULE__425__, __WEBPACK_EXTERNAL_MODULE__962__, __WEBPACK_EXTERNAL_MODULE__253__, __WEBPACK_EXTERNAL_MODULE__988__, __WEBPACK_EXTERNAL_MODULE__524__, __WEBPACK_EXTERNAL_MODULE__312__) => {
return 